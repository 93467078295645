/* CustomRangeWidget.css */
.range-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 8px;
    background: #ddd;
    outline: none;
}

.range-thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: #00520c;
    /* Change this color to your desired slider color */
    cursor: pointer;
    border-radius: 50%;
    margin-top: -8.5px;
    /* Center the thumb on the track */
}

.range-thumb::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: #00520c;
    /* Change this color to your desired slider color */
    cursor: pointer;
    border-radius: 50%;
}

.range-thumb::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(to right, #00520c 0%, #00520c var(--value), #ddd var(--value), #ddd 100%);
    border-radius: 5px;
}

.range-thumb::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: linear-gradient(to right, #00520c 0%, #00520c var(--value), #ddd var(--value), #ddd 100%);
    border-radius: 5px;
}