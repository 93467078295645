@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rjsf {
  color: #666666;
}

.form-group {
  padding-left: 2px;
  padding-right: 2px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
}

/* .add-button {
  background-color: #3498db !important;
  color: #ffffff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

/* .custom-form button {
  background-color: aquamarine !important;
  color: #000000 !important;
} */

.form-control {
  border-style: solid;
  border-color: #c4c7c6;
  border-width: 1px;
  border-radius: 4px;
  padding: 6px;
  color: #000000;
}

.custom-tooltip {
  max-width: 200px;
  /* Adjust the maximum width as needed */
}

.tooltip-icon {
  cursor: help;
  margin-left: 5px;
}

.bold-title {
  font-weight: 700;
}

.required {
  display: none;
}

.field-description {
  display: none;
}

.control-label-hidden {
  display: none;
}